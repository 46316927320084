import moment from "moment";
const formatToCommonReadableFormat=(date)=>{
    return moment(date).format("MMM DD, YYYY LT");
}

const formatToYearMonthDate=(date)=>{
    return moment(date).format("YYYY-MM-DD");
}

const secondsToMinuteAndSeconds=(seconds)=>{
    const m = Math.floor(seconds % 3600 / 60).toString(),
    s = Math.floor(seconds % 60).toString().padStart(2,'0');
    return `${m}:${s}`;
}
const settingsReportmonths = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
export {formatToCommonReadableFormat,settingsReportmonths,formatToYearMonthDate,secondsToMinuteAndSeconds}