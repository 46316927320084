import React from 'react'
import PropTypes from 'prop-types'
import ShIcon from 'Components/icons/ShIcon'
import { useWizard } from 'react-use-wizard'
import { Check } from 'Components/icons/Shoutout'
import './WizardHeader.scss'
const WizardHeader = ({ steps }) => {
  const { activeStep } = useWizard()

  return (
    <div className='d-flex flex-row wizard-header mb-2'>
      {steps.map(({ name, icon }, index) => {
          const isActive= activeStep > index;
        return (
          <>
            <div
              key={name}
              className={` d-flex flex-row flex-fill justify-content-center`}
            >
              <div className='d-flex flex-column align-items-center text-white'>
                <div className='icon-container-outer'>
                  <div>
                  <div
                    className={`${
                        isActive ? 'bg-success' : 'bg-white'
                    } rounded-circle icon-container d-flex flex-column align-items-center justify-content-center `}
                  >
                    <ShIcon
                      icon={isActive?Check:icon}
                      
                      className={`mb-0 ${isActive?'text-white':'text-primary'}`}
                    />
                  </div>
                  </div>
                </div>

                <p className='fw-light opacity-75 mb-2'>
                  <small>Step {index + 1}</small>
                </p>
                {name}
              </div>
            </div>
            {index < steps.length - 1 && (
              <div className='flex-fill line-container'>
                <div className='bg-white' />
              </div>
            )}
          </>
        )
      })}
    </div>
  )
}

WizardHeader.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      icon: PropTypes.elementType.isRequired
    })
  ).isRequired
}

export default WizardHeader
