const DefaultContext = {
  keycloakConfig: `${process.env.PUBLIC_URL}/assets/config/${process.env.REACT_APP_KEYCLOAK_FILE_NAME}`,
  baseUrl:
    process.env.REACT_APP_API_BASE_URL ||
    "https://betaenterprise.api.topnotch.club/api/",

  dataDogConfig: {
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: process.env.REACT_APP_DATADOG_SITE,
    service: process.env.REACT_APP_DATADOG_SERVICE,
    env: process.env.REACT_APP_DATADOG_ENV,
    version: process.env.REACT_APP_VERSION||"1.0.0"
  },
};

export default DefaultContext;
