import Crown from "assets/images/transaction-crown.svg";
import Donations from "assets/images/donation.svg";

const RewardType = {
    REDEEMED: "REDEEMED",
    UNLOCKED: "UNLOCKED"
};

const CardStatus = {
    PENDING: "PENDING",
    ACTIVE: "ACTIVE",
    ASSIGNED: "ASSIGNED",
    SUSPENDED: "SUSPENDED",
    DEACTIVATED: "DEACTIVATED"
};

const SharingTypes = {
    DONATE : "DONATE",
    POINT : "POINT",
}

const SharingDetails = {
    POINT: {
        name: "POINT",
        title: "Point Sharing",
        description: "Share your points with your loved ones as a gift. Now you can transfer your loyalty points with your family and friends who are Massy Loyalty Program members.",
        buttonTitle: "Share Your Points",
        icon: Crown
    },
    DONATE: {
        name: "DONATE",
        title: "Donations",
        description: "Everytime you donate your loyalty points to a particular charity, the points are credited to the charity's loyalty account, giving the receiving charity the oppotunity to redeem points and acquire items it may need.",
        buttonTitle: "Donate Points",
        icon: Donations
    },
}

export { 
    RewardType,
    CardStatus,
    SharingTypes,
    SharingDetails
};