//import VideoOne from "../assets/images/guideVideos/videoOne.PNG";
import VideoTwo from "../assets/images/guideVideos/videoTwo.PNG";
import VideoThree from "../assets/images/guideVideos/videoThree.PNG";
import VideoFour from "../assets/images/guideVideos/videoFour.PNG";
import VideoFive from "../assets/images/guideVideos/videoFive.PNG";
import VideoSix from "../assets/images/guideVideos/videoSix.PNG";
import VideoSeven from "../assets/images/guideVideos/videoSeven.PNG";


const GuideVideosData = [
   // {image:VideoOne,videoLink:"https://www.youtube.com/embed/jQkMyIEUMBc",description:"How to sign up without a Massy Loyalty Card"},
    {image:VideoTwo,videoLink:"https://www.youtube.com/embed/IQKBPIzJf54",description:"How to update your Profile information"},
    {image:VideoThree,videoLink:"https://www.youtube.com/embed/xzq8myQLEiw",description:"How to sign up with a Massy Loyalty Card"},
    {image:VideoFour,videoLink:"https://www.youtube.com/embed/MjndPw_RMaI",description:"How to connect or deactivate a Massy Loyalty Card"},
    {image:VideoFive,videoLink:"https://www.youtube.com/embed/2BmlBMCOUZc",description:"How to share Massy Points"},
    {image:VideoSix,videoLink:"https://www.youtube.com/embed/RBiNwiaQu3Q",description:"How to check points balance and points expiration"},
    {image:VideoSeven,videoLink:"https://www.youtube.com/embed/cYNIGdgQR9M",description:"How to donate points to charities"},
]

export {GuideVideosData};
