const toTitleCase = text => {
    let upper = true;
    let newStr = "";
    const str=text.replace(/[-_]/g," ").trim();
    for (let i = 0, l = str.length; i < l; i++) {
        if (str[i] === " ") {
        upper = true;
        newStr += " ";
        continue;
        }
        newStr += upper ? str[i].toUpperCase() : str[i].toLowerCase();
        upper = false;
    }
    return newStr;
};
  
const toTitleCaseFromCamelCase = text => {
    const result = text.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
};
  
const toReplaceSpaceWithHyphen = text => {
    const result = text.replace(/ +/g, '-').toLowerCase();
    return result;
};

export { 
    toTitleCase,
    toTitleCaseFromCamelCase,
    toReplaceSpaceWithHyphen
};
  