import { Key, User } from "Components/icons/Shoutout";
import VerfiyEmail from "assets/images/verify-email.svg";
import VerfiyCustomerService from "assets/images/verify-customer-service.svg";
import UpdatePasswordIcon from "assets/images/update-password.svg";
//import Export from "assets/images/export.svg";
//import TrashBin from "assets/images/trash-bin.svg";

const NavList = [
  {
    tab: "Account Details",
    path: "",
  },
  {
    tab: "Loyalty Cards",
    path: "loyalty-cards",
  },
  {
    tab: "Family Sharing",
    path: "family-sharing",
  },
  {
    tab: "Loyalty Transactions",
    path: "loyalty-transactions",
  },
  {
    tab: "Security Controls",
    path: "security-controls",
  },
  {
    tab: "Other Loyalty Programs",
    path: "other-loyalty-programs",
  },
];

const AccountType = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY",
};

const DetailsType = {
  BASIC: "BASIC",
  CONTACT: "CONTACT",
  PARTNER: "PARTNER"
};

const PreferredContactTypes = {
  EMAIL:"EMAIL",
  MOBILE:"MOBILE",
  EMAIL_AND_MOBILE:"EMAIL_AND_MOBILE",
};

const LoyaltyCardStatus = {
  ACTIVE: "ACTIVE",
  ASSIGNED: "ASSIGNED",
  DEACTIVATED: "DEACTIVATED"
};

const TransactionType = {
  COLLECTION: "COLLECTION",
  REDEMPTION: "REDEMPTION",
  ADJUSTMENT: "ADJUSTMENT"
};

const OperationType = {
  SUBTRACT: "SUBTRACT",
  ADD: "ADD"
};

const PasswordMethods = {
  EMAIL: "EMAIL",
  CUSTOMER_SERVICE: "CUSTOMER_SERVICE"
};

const PasswordMethodsData = [
  {
    method: PasswordMethods.EMAIL,
    title: "By Email Verification",
    description: "You can update your password using the registered email address or mobile number",
    imageUrl: VerfiyEmail,
    actionButton: true
  },
  {
    method: PasswordMethods.CUSTOMER_SERVICE,
    title: "By Contacting Customer Service",
    description: "Please contact customer service on",
    imageUrl: VerfiyCustomerService,
    actionButton: false
  }
];

const UpdatePasswordWizardSteps = [
  { name: 'Account Verification', icon: User },
  { name: 'Update Password', icon: Key }
];

const SecurityControlMethods = {
  UPDATE_PASSWORD: "UPDATE_PASSWORD",
  EXPORT_DATA: "EXPORT_DATA",
  DELETE_ACCOUNT: "DELETE_ACCOUNT"
};

const SecurityControlsData = [
  {
    method: SecurityControlMethods.UPDATE_PASSWORD,
    title: "Update your password",
    imageUrl: UpdatePasswordIcon,
    actionButton: true
  },
  /*{
    method: SecurityControlMethods.EXPORT_DATA,
    title: "You can export your data from Massy Loyalty",
    imageUrl: Export,
    actionButton: true
  },
  {
    method: SecurityControlMethods.DELETE_ACCOUNT,
    title: "Deleting your account will remove all of your information from our database",
    imageUrl: TrashBin,
    actionButton: true
  }*/
];

export {
    NavList,
    AccountType,
    DetailsType,
    LoyaltyCardStatus,
    TransactionType,
    PasswordMethods,
    PasswordMethodsData,
    UpdatePasswordWizardSteps,
    SecurityControlMethods,
    SecurityControlsData,
    PreferredContactTypes,
    OperationType
};
