import * as React from "react";

const SvgCheck = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "currentColor",
        fillOpacity: 1,
      }}
      d="M9 16.2 4.8 12l-1.402 1.398L9 19 21 7l-1.398-1.398Zm0 0"
    />
  </svg>
);

export default SvgCheck;
