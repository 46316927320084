import { fetchGet } from "./CommonServiceUtils";
import Constants from "../Constants";

const BASE_URL = `${Constants.BASE_URL}loyaltyservice/portal/organizations`;

const getOrganizationDetails = () => {
  return fetchGet(BASE_URL);
};

export { getOrganizationDetails };
