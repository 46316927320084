import { fetchGet, fetchPost, jsonToQueryParam } from './CommonServiceUtils'
import Constants from '../Constants'

const BASE_URL = `${Constants.BASE_URL}loyaltyservice/portal/members`

const validateMember = payload => {
    return fetchPost(`${BASE_URL}/validate`, payload)
}

const getSecondaryMembers = payload => {
    return fetchGet(`${BASE_URL}?${jsonToQueryParam(payload)}`);
}

export { validateMember, getSecondaryMembers }
