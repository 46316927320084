import * as React from "react";

const SvgCardSolid = (props) => (
  <svg
    viewBox="0 0 49 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      width={49}
      height={49}
      rx={24.5}
      fill="currentColor"
      fillOpacity={0.15}
    />
    <path
      d="M12 17.5a3.25 3.25 0 0 1 3.25-3.25h19.5A3.25 3.25 0 0 1 38 17.5v8.125H12V17.5Zm18.688 1.625a.812.812 0 0 0-.813.813v1.625a.812.812 0 0 0 .813.812h3.25a.812.812 0 0 0 .812-.813v-1.625a.812.812 0 0 0-.813-.812h-3.25ZM12 28.875V30.5a3.25 3.25 0 0 0 3.25 3.25h19.5A3.25 3.25 0 0 0 38 30.5v-1.625H12Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgCardSolid;
