import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { KeycloakContext } from "Contexts";
import {
  getAccountDetails,
  getAllPublicRegions,
  getOrganizationDetails,
  getRewards,
} from "Services";
import { RewardSubTypes } from "Data";
import { toast } from "react-toastify";
import { useKeycloak } from "@react-keycloak/web";
import {
  getRegionalPointConfiguration,
  getTiers,
} from "Services/LoyaltyService";
import { getQueryParam } from "Utils";

const UserContext = React.createContext();

const UserContextActions = {
  SET_ORGANIZATION: "setOrganization",
  SET_REGIONS: "setRegions",
  SET_POINTS_CONFIG: "setPointConfig",
  SET_PARTNER_REWARDS_LIST: "setPartnerRewardsList",
  IS_LOADING_PARTNER_REWARDS: "isLoadingPartnerRewards",
  SET_TIERS: "setTiers",
  IS_LOADING_USER: "isLoadingUser",
  SET_PROFILE_DATA: "setProfileData",
  IS_EDITING_USER: "isEditingUser",
  RELOAD_POINTS: "reloadPoints",
  SET_CARD_CONFIGURATION: "setCardConfiguration",
};

const initialRegionState = {
  _id: "",
  organizationId: "",
  regionName: "Barbados",
  defaultCountryISO2Code: "BB",
  defaultCurrencyCode: "BBD",
  regionIconUrl:
    "https://betaenterprise.api.topnotch.club/api/coreservice/files/03867b60-41d4-11ec-8edd-f90b42e29ca4.png",
};

const initialState = {
  isRunningSystemInit: true,
  regionId: "",
  PartnerRewardsList: "",
  loadingPartnerRewards: false,
  organization: {
    organizationName: "",
    organizationLogoImageUrl: "",
    address: {
      street: "",
      city: "",
      zip: "",
    },
    regions: [],
    configuration: {
      cardConfiguration: {
        loyaltyCardNumberLength: 11,
      },
    },
  },
  regionConfig: {},
  tiers: [],
  loadingUser: false,
  editingUser: false,
  reloadingPoints: false,
  rewardMetadata: [],
};

//
const reducer = (state, action) => {
  // console.debug("USER ACTION:", action);
  switch (action.type) {
    case UserContextActions.SET_ORGANIZATION: {
      // if (action.organization?.regions?.length > 0) {
      //   updatedState.regionId = action.organization.regions[0]._id
      //   updatedState.selectedRegion = action.organization.regions[0]
      // }
      return {
        ...state,
        organization: {
          ...action.organization,
          configuration: {
            ...state.organization.configuration,
            ...action.organization.configuration,
          },
        },
        isRunningSystemInit: false,
        systemInitFailMessage: null,
      };
    }

    case UserContextActions.IS_LOADING_USER: {
      return { ...state, loadingUser: action.status };
    }
    case UserContextActions.SET_CARD_CONFIGURATION: {
      return {
        ...state,
        organization: {
          ...state.organization,
          configuration: {
            ...state.organization.configuration,
            cardConfiguration: {
              loyaltyCardNumberLength: action.loyaltyCardNumberLength,
            },
          },
        },
      };
    }

    case UserContextActions.IS_LOADING_PARTNER_REWARDS: {
      return { ...state, loadingPartnerRewards: action.status };
    }
    case UserContextActions.SET_PROFILE_DATA: {
      return { ...state, ...action.data };
    }

    case UserContextActions.IS_EDITING_USER: {
      return { ...state, editingUser: action.status };
    }

    case UserContextActions.SET_POINTS_CONFIG: {
      return { ...state, regionConfig: action.configData };
    }

    case UserContextActions.SET_TIERS: {
      return { ...state, tiers: action.tiersData };
    }

    case UserContextActions.RELOAD_POINTS: {
      return { ...state, reloadingPoints: action.status };
    }
    case UserContextActions.SET_PARTNER_REWARDS_LIST: {
      return { ...state, PartnerRewardsList: action.status };
    }

    case UserContextActions.SET_REGIONS: {
      return {
        ...state,
        organization: { ...state.organization, regions: action.regions },
        regionId: action.selectedRegion
          ? action.selectedRegion._id
          : state.regionId,
      };
    }
    default:
      return state;
  }
};

const UserContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    keycloakLogout: logout,
    login: keycloakLogin,
    authComplete,
  } = useContext(KeycloakContext);

  const { keycloak, initialized } = useKeycloak();
  const isAuth = keycloak.authenticated || false;

  const loadProfile = useCallback(async () => {
    try {
      dispatch({
        type: UserContextActions.IS_LOADING_USER,
        status: true,
      });
      const profileResponse = await getAccountDetails();
      console.debug("PROFILE:", profileResponse);
      dispatch({
        type: UserContextActions.SET_PROFILE_DATA,
        data: profileResponse,
      });
      dispatch({
        type: UserContextActions.IS_LOADING_USER,
        status: false,
      });
    } catch (e) {
      dispatch({
        type: UserContextActions.IS_LOADING_USER,
        status: false,
      });
      console.error(e);
      toast.error(
        <div>
          Failed to load your profile!
          <br />
          {e.message ? `Error: ${e.message}` : "Please try again later."}
          <br />
          If the issue persists, please contact support.
        </div>
      );
    }
  }, [dispatch]);

  const loadOrganization = useCallback(async () => {
    try {
      const organizationResponse = await getOrganizationDetails();
      dispatch({
        type: UserContextActions.SET_ORGANIZATION,
        organization: organizationResponse,
      });
    } catch (e) {
      // dispatch({
      //   type: UserContextActions.SET_INIT_SYSTEM_ERROR_MESSAGE,
      //   message:
      //     "Could not load your organization details. Please retry. If issue persist, contact the support.",
      // });
    }
  }, [dispatch]);

  const loadPartnerRewardsList = useCallback(async () => {
    const queryObj = {
      limit: 10,
      skip: 0,
      subType: RewardSubTypes.PARTNER,
    };
    try {
      dispatch({
        type: UserContextActions.IS_LOADING_PARTNER_REWARDS,
        status: true,
      });
      const rewardsResponse = await getRewards(queryObj);
      dispatch({
        type: UserContextActions.SET_PARTNER_REWARDS_LIST,
        status: rewardsResponse.items,
      });
      dispatch({
        type: UserContextActions.IS_LOADING_PARTNER_REWARDS,
        status: false,
      });
    } catch (e) {
      dispatch({
        type: UserContextActions.IS_LOADING_PARTNER_REWARDS,
        status: false,
      });
      console.error(e);
      toast.error(
        <div>
          Failed to load partner rewards!
          <br />
          {e.message ? `Error: ${e.message}` : "Please try again later."}
        </div>
      );
    }
  }, [dispatch]);

  const loadPointsConfig = useCallback(async () => {
    try {
      const pointsConfigResponse = await getRegionalPointConfiguration();
      dispatch({
        type: UserContextActions.SET_POINTS_CONFIG,
        configData: pointsConfigResponse,
      });
    } catch (e) {
      toast.error(
        <div>
          Failed to load points configurations!
          <br />
          {e.message ? `Error: ${e.message}` : "Please try again later."}
        </div>
      );
    }
  }, [dispatch]);

  const loadTiers = useCallback(async () => {
    try {
      const tiersResponse = await getTiers({});
      dispatch({
        type: UserContextActions.SET_TIERS,
        tiersData: tiersResponse.items,
      });
    } catch (e) {
      toast.error(
        <div>
          Failed to load tiers!
          <br />
          {e.message ? `Error: ${e.message}` : "Please try again later."}
        </div>
      );
    }
  }, [dispatch]);

  const setIsEditingUser = useCallback(
    (status) => dispatch({ type: UserContextActions.IS_EDITING_USER, status }),
    [dispatch]
  );

  const setEditedProfile = useCallback(
    (response) =>
      dispatch({ type: UserContextActions.SET_PROFILE_DATA, data: response }),
    [dispatch]
  );

  const refreshUserPoints = useCallback(async () => {
    try {
      dispatch({
        type: UserContextActions.RELOAD_POINTS,
        status: true,
      });
      const profileResponse = await getAccountDetails();
      dispatch({
        type: UserContextActions.SET_PROFILE_DATA,
        data: profileResponse,
      });
      dispatch({
        type: UserContextActions.RELOAD_POINTS,
        status: false,
      });
    } catch (e) {
      dispatch({
        type: UserContextActions.RELOAD_POINTS,
        status: false,
      });
      console.error(e);
      toast.error(
        <div>
          Failed to refresh user points!
          <br />
          {e.message ? `Error: ${e.message}` : "Please try again later."}
        </div>
      );
    }
  }, []);

  const loadPublicRegions = useCallback(async () => {
    try {
      const regionResponse = await getAllPublicRegions();

      const userSelectedRegionISOCode = getQueryParam("lng");

      let userRegion = regionResponse.find((item) => item.isDefaultRegion);
      if (userSelectedRegionISOCode) {
        const selectedRegion = regionResponse.find(
          (item) =>
            item.defaultCountryISO2Code.toLowerCase() ===
            userSelectedRegionISOCode
        );
        if (selectedRegion) {
          userRegion = selectedRegion;
        }
      }
      dispatch({
        type: UserContextActions.SET_REGIONS,
        regions: regionResponse,
        selectedRegion: userRegion,
      });
      if (userRegion.cardConfiguration.length !== 0) {
        dispatch({
          type: UserContextActions.SET_CARD_CONFIGURATION,
          loyaltyCardNumberLength: userRegion.cardConfiguration[0].cardNoLength,
        });
      }
    } catch (error) {}
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      if (authComplete) {
        loadProfile();
        loadOrganization();
        loadPointsConfig();
        loadTiers();
        loadPartnerRewardsList();
      }
    })();
    // eslint-disable-next-line
  }, [authComplete]);

  useEffect(() => {
    loadPublicRegions();
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   if (token) {
  //     login(token)
  //   }
  //   //eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [token])

  const selectedRegion = useMemo(() => {
    if (state.regionId && state.organization?.regions) {
      return (
        state.organization.regions.find(
          (item) => item._id === state.regionId
        ) || initialRegionState
      );
    }
    return initialRegionState;
  }, [state.regionId, state.organization?.regions]);

  const value = {
    ...state,
    selectedRegion,
    isAuth,
    initialized,
    username: state.firstName,
    login: keycloakLogin,
    logout,
    setIsEditingUser,
    setEditedProfile,
    refreshUserPoints,
    loadProfile,
  };

  // console.debug("USER CONTEXT:", value);

  return (
    <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
  );
};

const UserContextConsumer = UserContext.Consumer;

export {
  UserContext,
  UserContextProvider,
  UserContextConsumer,
  UserContextActions,
};
