import { lazy, Suspense, useEffect } from "react";
import { KeycloakContextProvider, UserContextProvider } from "Contexts";
import { Route, Routes } from "react-router-dom";
import LoadingComponent from "Components/utils/loading/LoadingComponent";
import HomePage from "Components/home/Home";
import AppContext from "AppContext";
import AuthRoute from "AuthRoute";
import { datadogRum } from "@datadog/browser-rum";
import { ToastContainer } from "react-toastify";
import userhelperlibrary from "userhelperlibrary";
import CookieConsent from "react-cookie-consent";
import "react-toastify/dist/ReactToastify.css";

const AccountPage = lazy(() => import("Components/accounts/AccountPage"));
const SignUpPage = lazy(() => import("Components/signUp/SignUpPage"));
const LoginPage = lazy(() => import("Components/login/LoginPage"));
const GuideVideos = lazy(() => import("Components/guideVideos/GuideVideos"));
const LandingPage = lazy(() => import("Components/landingPage/LandingPage"));
const TermsAndConditions = lazy(() => import("Components/termsAndConditions/TermsAndConditions"));
const FrequentlyAskedQuestions = lazy(() => import("Components/fAQPage/FAQPage"));
const ConfidentialityPolicy = lazy(() => import("Components/termsAndConditions/ConfidentialityPolicy"));
const DataProtectionPolicy = lazy(() => import("Components/termsAndConditions/DataProtectionPolicy"));
function App() {
  useEffect(() => {
    userhelperlibrary();
    if (!(!process.env.NODE_ENV || process.env.NODE_ENV === "development")) {
      datadogRum.init({
        sampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: "mask-user-input",
        ...AppContext.dataDogConfig,
      });

      datadogRum.startSessionReplayRecording();
      return () => datadogRum.stopSessionReplayRecording();
    }
  }, []);

  return (
    <div className="App">
      <ToastContainer theme="colored" />
      <KeycloakContextProvider>
        <UserContextProvider>
          {/* <Switch>
            <CustomRoute
              name="LoginPage"
              exact
              path="/login"
              component={AccountPage}
            />
            <AuthRoute path="/" component={HomePage} />
          </Switch> */}
          <Suspense fallback={<LoadingComponent />}>
            <Routes>
              <Route path="login" element={<LoginPage />} />
              <Route path="signup" element={<SignUpPage />} />
              <Route path="account" element={<AccountPage />} />
              <Route path="terms-conditions" element={<TermsAndConditions />} />
              <Route path="frequently-asked-questions" element={<FrequentlyAskedQuestions />} />
              <Route path="guide-videos" element={<GuideVideos />} />
              <Route path="data-protection-policy" element={<DataProtectionPolicy />} />
              <Route path="confidentiality-policy" element={<ConfidentialityPolicy />} />
              <Route
                path="home/*"
                element={
                  <AuthRoute>
                    <HomePage />
                  </AuthRoute>
                }
              />
              <Route path="/*" element={<LandingPage />} />
            </Routes>
          </Suspense>
        </UserContextProvider>
      </KeycloakContextProvider>
      <CookieConsent buttonStyle={{  fontSize: "1rem", borderRadius:"100px" }}>This website uses cookies to enhance the user experience.</CookieConsent>
    </div>
  );
}

export default App;
