import { fetchGet,jsonToQueryParam, fetchPost} from './CommonServiceUtils';
import Constants from '../Constants';

const BASE_URL = `${Constants.BASE_URL}loyaltyservice/portal/`;

const getCharitiesList = (queryObj) => {
    return fetchGet(`${BASE_URL}charities?${jsonToQueryParam(queryObj)}`);
};

const donatePoints = (payload) => {
    return fetchPost(`${BASE_URL}points/transfer`, payload);
};

const getPointsReceiver = (payload) => {
    return fetchPost(`${BASE_URL}members/validate`, payload);
};

export {
    getCharitiesList,
    donatePoints,
    getPointsReceiver
 };