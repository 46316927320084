import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
import { getQueryParam } from "Utils";
import MassyStores from "./assets/images/partners/massy-stores.svg";
import CaribbeanAirlines from "./assets/images/partners/caribbean-airlines.png";
import MassyMotors from "./assets/images/partners/massy-motors.png";
import massyAutomotiveComponentsLimited from "./assets/images/partners/massy-automotive-components-limited.png";
import MassyFinance from "./assets/images/partners/massy-finance.png";
import Trinpad from "./assets/images/partners/trinpad.png";
import FerreiraOptical from "./assets/images/partners/ferreira-optical.png";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  bb: {
    translation: {
      partner: {
        commonCardContent:[
          {image:MassyStores},
          {image:CaribbeanAirlines},
          {image:MassyMotors},
          {image:massyAutomotiveComponentsLimited},
          {image:MassyFinance},
          {image:Trinpad},
          {image:FerreiraOptical},
        ],
        slidesPerView:3
      },
      footer: {
        socialMedia: {
          facebook: "https://www.facebook.com/massystoresbb/",
          instagram: "https://www.instagram.com/massystoresbb/?hl=en",
          twitter: "",
          youtube: "",
        },
        headOffice: {
          address: "Sargeant’s Village, Christ Church,\nBridgetown, Barbados",
          contactNumber: "+1 246 434 0482",
          email: "",
        },
        customerSupport: {
          contactNumber: "+1 246 430 4940",
          email: "customerservice.bb@massystores.com",
        },
        cardInquiry: {
          contactNumber: "+ 1 246 418-CARD (2273)",
          email: "info.bb@massycard.com",
        },
      },
      homepage:{
        point:{
          description:"Cardholders may choose to share their Massy Points with other loyalty cardholders. Once you've accumulated {{minimumPointBalance}} or more Massy Points, transfer them to a loved one so that they too can save in-store. You can also choose to have secondary cardholders on your loyalty account and pool all earned Massy Points together."
        },
        donate:{
          description:"Cardholders may choose to dontate 100 or more of their Massy Points to a local partner charity within the programme."
        }
      },
      about:{
        earnPoints:{
          description:"Every $10 you spend at any Massy Store location earns you 1 Massy Point*. Each Massy Point is worth $0.10 when redeemed at any redemption partner locations. You also earn Massy Points at our loyalty partners,  Massy Trading, The Grape Vine, RUBIS, Kooyman Megstore, SurePay and eZone. Just present your card at the time of your transaction to instantly earn your points."
        },
        redeemPoints:{
          description:"Earning is just half the Reward! Once you have accumulated 100 or more Massy Points, you may redeem them at anytime in store at Massy Stores Supermarket, SuperCentre, Home, Pharmacy or Express. You may also redeem your Massy Points at our participating partner locations or for exciting partner vouchers. Unsed Massy Points expire 15 months after they were earned."
        },
        tiers:{
          description:"The more you shop, the more you earn. Frequent shoppers at Massy Stores earn Massy Points fasterin our teir statuses. Tier statuses are automatically assigned and your benefits are instant."
        },
        merchants:{
          description:""
        }
      }
    },
  },
  gy: {
    translation: {
      partner: {
        commonCardContent:[
          {image:MassyStores},
        ],
        slidesPerView:1
      },
      footer: {
        socialMedia: {
          facebook: "https://www.facebook.com/MassyStoresGuyana",
          instagram: "https://www.instagram.com/massystoresguyana/?hl=en",
          twitter: "",
          youtube: "",
        },
        headOffice: {
          address: "Amazonia Mall, Providence,\nEast Bank Guyana, Guyana.",
          contactNumber: "222-7229",
          email: "custfeedhelpdesk.gy@massystores.com",
        },
        customerSupport: {
          contactNumber: "",
          email: "custfeedhelpdesk.gy@massystores.com",
        },
        cardInquiry: {
          contactNumber: "222-7230 ext 23534 or 23634",
          email: "",
        },
      },
      homepage:{
        point:{
          description:"Cardholders may choose to share their Massy Points with other loyalty cardholders. Once you've accumulated {{minimumPointBalance}} or more Massy Points, transfer them to a loved one so that they too can save instore. You can also choose to have secondary cardholders on your loyalty account and pool all earned points together.  Click here for more details."
        },
        donate:{
          description:"Cardholders may choose to dontate 100 or more of their Massy Points to a local partner charity within the programme."
        }
      },
      about:{
        earnPoints:{
          description:"Every $10 you spend at any Massy Store location earns you 1 Massy Point*. Each Massy Point is worth $0.10 when redeemed n store for, groceries, home goods, pharmaceuticals and your bulk purchases."
        },
        redeemPoints:{
          description:"Earning is just half the Reward! Once you have accumulated 5000 or more Massy Points, you may redeem them at anytime in store at Massy Stores Supermarket, Mega, or Pharmacy. Unsed Massy Points expire 15 months after they were earned."
        },
        tiers:{
          description:"The more you shop, the more you earn. Frequent shoppers at Massy Stores earn Massy Points fasterin our teir statuses. Tier statuses are automatically assigned and your benefits are instant."
        },
        merchants:{
          description:""
        }
      }
    },
  },

  lc: {//st lucia
    translation: {
      partner: {
        commonCardContent:[
          {image:MassyStores},
          {image:CaribbeanAirlines},
          {image:MassyMotors},
          {image:massyAutomotiveComponentsLimited},
          {image:MassyFinance},
          {image:Trinpad},
          {image:FerreiraOptical},
        ],
        slidesPerView:3
      },
      footer: {
        socialMedia: {
          facebook: "https://www.facebook.com/MassyStoresGourmet/",
          instagram: "https://www.instagram.com/massystoresslu/?hl=en",
          twitter: "",
          youtube: "https://www.youtube.com/channel/UCem7WjvoFw0NLfgNdVQbkVg",
        },
        headOffice: {
          address: "Choc Estate\nCastries",
          contactNumber: "1-758-457-2000",
          email: "",
        },
        customerSupport: {
          contactNumber: "",
          email: "customercare.slu@massystores.com",
        },
        cardInquiry: {
          contactNumber: "457-2404",
          email: "customercare.slu@massystores.com",
        },
      },
      homepage:{
        point:{
          description:"Cardholders may choose to share their Massy Points with other loyalty cardholders. Once you've accumulated {{minimumPointBalance}} or more Massy Points, transfer them to a loved one so that they too can save instore. You can also choose to have secondary cardholders on your loyalty account and pool all earned points together."
        },
        donate:{
          description:"Cardholders may choose to dontate 100 or more of their Massy Points to a local partner charity within the programme."
        }
      },
      about:{
        earnPoints:{
          description:"Every $1 you spend at any Massy Stores location earns you 1 Massy Point*. Each Massy Point is worth $0.01 when redeemed in-store."
        },
        redeemPoints:{
          description:"Earning is just half the Reward! Once you have accumulated 500 or more Massy Points, you may redeem them at anytime in store at Massy Stores Supermarket, Mega, or Pharmacy. Unsed Massy Points expire 15 months after they were earned."
        },
        tiers:{
          description:"The more you shop, the more you earn. Frequent shoppers at Massy Stores earn Massy Points faster in our teir statuses. Tier statuses are automatically assigned and your benefits are instant."
        },
        merchants:{
          description:""
        }
      }
    },
  },
  tt: {
    translation: {
      partner: {
        commonCardContent:[
          {image:MassyStores},
          {image:CaribbeanAirlines},
          {image:MassyMotors},
          {image:massyAutomotiveComponentsLimited},
          {image:MassyFinance},
          {image:Trinpad},
          {image:FerreiraOptical},
        ],
        slidesPerView:3
      },
      footer: {
        socialMedia: {
          facebook: "https://www.facebook.com/massystorestt/",
          instagram: "https://www.instagram.com/massystorestt/?hl=en",
          twitter: "https://twitter.com/massystorestt?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
          youtube: "https://www.youtube.com/channel/UCE7kJ5NKJdugs2OfhCs7yYQ",
        },
        headOffice: {
          address: "39A Wrightson Road, Port of Spain ",
          contactNumber: "(868) 609-4456",
          email: "",
        },
        customerSupport: {
          contactNumber: "(868) 768-7225 / (868) 489-3640",
          email: "customerexperience.tt@massystores.com",
        },
        cardInquiry: {
          contactNumber: "(868) 489-3559 / (868) 737-1669",
          email: "info.tt@massycard.com",
        },
      },
      homepage:{
        point:{
          description:"Cardholders may choose to share their Massy Points with other loyalty cardholders. Once you've accumulated {{minimumPointBalance}} or more Massy Points, transfer them to a loved one so that they too can save in-store. You can also choose to have secondary cardholders on your loyalty account and pool all earned Massy Points together."
        },
        donate:{
          description:"Donate your Massy points to a particular charity, giving the receiving charity the opportunity to redeem points and acquire items it may need. Your Massy points will be debited from your loyalty account and credited to the charity’s loyalty account."
        }
      },
      about:{
        earnPoints:{
          description:"Every $10 you spend at participating Massy merchants earns 1 point. Just present your card prior to completion of the transaction* and points will be transferred to your account"
        },
        redeemPoints:{
          description:"Earning is just half the Reward! Once you have accumulated 400 or more Massy Points, you may redeem them at anytime in store at Massy Stores Supermarket, SuperCentre, Pharmacy or Express. You may also redeem your Massy Points at our participating partner loctions."
        },
        tiers:{
          description:"The more you shop, the more you earn. Frequent shoppers at Massy Stores earn Massy Points fasterin our teir statuses. Tier statuses are automatically assigned and your benefits are instant."
        },
        merchants:{
          description:"Earn points when you spend $10 or more at these Massy merchants."
        }
      }
    },
  },
  vc: {//st vincent
    translation: {
      partner: {
        commonCardContent:[
          {image:MassyStores},
          {image:CaribbeanAirlines},
          {image:MassyMotors},
          {image:massyAutomotiveComponentsLimited},
          {image:MassyFinance},
          {image:Trinpad},
          {image:FerreiraOptical},
        ],
        slidesPerView:3
      },
      footer: {
        socialMedia: {
          facebook: "https://www.facebook.com/MassyStoresSVG",
          instagram: "https://www.instagram.com/massystoressvg/?hl=en",
          twitter: "",
          youtube: "https://www.youtube.com/channel/UCaDLALNTJzUYLLGv7hIeKrg",
        },
        headOffice: {
          address: "Upper Bay Street\nKingstown",
          contactNumber: "1784-457-1603",
          email: "customercaresvg@massystores.com",
        },
        customerSupport: {
          contactNumber: "",
          email: "customercaresvg@massystores.com",
        },
        cardInquiry: {
          contactNumber: "453-9120",
          email: "customercaresvg@massystores.com",
        },
      },
      homepage:{
        point:{
          description:"Cardholders may choose to share their Massy Points with other loyalty cardholders. Once you've accumulated {{minimumPointBalance}} or more Massy Points, transfer them to a loved one so that they too can save instore. You can also choose to have secondary cardholders on your loyalty account and pool all earned points together."
        },
        donate:{
          description:"Cardholders may choose to dontate 100 or more of their Massy Points to a local partner charity within the programme."
        }
      },
      about:{
        earnPoints:{
          description:"Every $10 you spend at participating Massy merchants earns 1 point. Just present your card prior to completion of the transaction* and points will be transferred to your account"
        },
        redeemPoints:{
          description:"Earning is just half the Reward! Once you have accumulated 400 or more Massy Points, you may redeem them at anytime in store at Massy Stores Supermarket, SuperCentre, Pharmacy or Express. You may also redeem your Massy Points at our participating partner loctions."
        },
        tiers:{
          description:"The more you shop, the more you earn. Frequent shoppers at Massy Stores earn Massy Points fasterin our teir statuses. Tier statuses are automatically assigned and your benefits are instant."
        },
        merchants:{
          description:"Earn points when you spend $10 or more at these Massy merchants."
        }
      }
    },
  },
};

const languagedetectorOptions = {
  // order and from where user language should be detected
  order: [
    "querystring",
    "cookie",
    "localStorage",
    "sessionStorage",
    "navigator",
    "htmlTag",
    "path",
    "subdomain",
  ],

  // keys or params to lookup language from
  lookupQuerystring: "lng",
  lookupCookie: "i18next",
  lookupLocalStorage: "i18nextLng",
  lookupSessionStorage: "i18nextLng",
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ["localStorage", "cookie"],
  excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: "massystore.com",

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  cookieOptions: { path: "/", sameSite: "strict" },
};

i18n
  // .use(LanguageDetector) // passes i18n down to react-i18next
  .use(initReactI18next)
  .init({
    detection: languagedetectorOptions,
    fallbackLng: "tt",
    resources,
    lng:  getQueryParam("lng"), // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
