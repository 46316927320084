import * as React from "react";

const SvgKey = (props) => (
  <svg
    viewBox="0 0 33 36"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#key_svg__a)">
      <path d="M19.712 19.646 17 22.592h-2.4v2.606h-2.4v2.607l-2.4 2.607H5v-5.214l9.912-10.765a8.456 8.456 0 0 1 .172-5.085c.578-1.626 1.64-2.996 3.017-3.893a6.735 6.735 0 0 1 4.582-1.053c1.593.213 3.075.998 4.21 2.231 1.135 1.233 1.858 2.842 2.055 4.573a8.378 8.378 0 0 1-.97 4.977c-.826 1.495-2.087 2.648-3.585 3.276a6.664 6.664 0 0 1-4.681.187Zm5.832-6.021a4.025 4.025 0 0 0 .984-2.002 4.223 4.223 0 0 0-.205-2.257 3.86 3.86 0 0 0-1.325-1.754A3.4 3.4 0 0 0 23 6.952c-.954 0-1.87.413-2.544 1.147l5.088 5.526Z" />
    </g>
    <defs>
      <clipPath id="key_svg__a">
        <path
          fill="#fff"
          transform="translate(5 4.345)"
          d="M0 0h24v26.067H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgKey;
