import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ErrorBoundary from "./ErrorBoundary";
import './i18n';

import 'theme/dist/css/bootstrap.scss';
import "./index.css";

ReactDOM.render(
  <React.StrictMode>
   <BrowserRouter>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
   </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
