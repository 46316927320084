import * as React from "react";

const SvgInfo = (props) => (
  <svg
    viewBox="0 0 24 25"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M22 12.013c0 5.524-4.477 10-10 10s-10-4.476-10-10c0-5.522 4.477-10 10-10s10 4.478 10 10" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.87 12.644a.877.877 0 0 1-.875.875.877.877 0 0 1-.875-.875v-4.42c0-.482.393-.875.875-.875s.875.393.875.875v4.42Zm-1.745 3.172a.878.878 0 0 1 1.755 0 .876.876 0 0 1-.875.875.879.879 0 0 1-.88-.875Z"
      fill="#fff"
    />
  </svg>
);

export default SvgInfo;
