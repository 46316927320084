import React from 'react';


const Heading = ({children,className,...rest}) => {
    return (
        <div className={`heading ${className||''}`} {...rest}>
            {children}
        </div>
    );
};


export default Heading;