import React, { lazy, Suspense,useContext ,useMemo} from "react";
import Navigation from "../navigation/Navigation";
import { UserContext } from "Contexts";
import {
  Route,
  Routes,
  // useLocation,
  // useHistory,
  // useNavigate
} from "react-router-dom";
import Header from "./header/Header";
import { toReplaceSpaceWithHyphen, toTitleCase } from "Utils";
import { LoadingComponent } from "Components/utils";

//import qs from "qs";

const HomePage = lazy(() => import("Components/homeView/HomeViewPage"));
const RewardsPage = lazy(() => import("Components/rewards/RewardsPage"));
const DonationsPage = lazy(() => import("Components/donations/DonationsPage"));
const CalMilesPage = lazy(() => import("Components/calMiles/CalMilesPage"));
const AboutPage = lazy(() => import("Components/about/AboutPage"));
const ProfilePage = lazy(() => import("Components/profile/ProfilePage"));


// const RequireAuth=({ children })=> {
//   const {isAuth}=useContext(UserContext);

//   return authed === true
//     ? children
//     : <Navigate to="/login" replace />;
// }

const Home = (props) => {

  // const navigate = useNavigate();
  // const location = useLocation();

  // useEffect(() => {
  //     setTimeout(() => {
  //         navigate.replace({
  //         pathname: "/refresh",
  //         search: "?url=" + encodeURIComponent(location.pathname),
  //       });
  //     }, 0);

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  const { PartnerRewardsList,loadingPartnerRewards } = useContext(UserContext);

  const partnerRewardDynamicNavList = useMemo(() => !loadingPartnerRewards && PartnerRewardsList &&
      PartnerRewardsList.map(reward => ({
          name: toTitleCase(reward.name),
          path: `${toReplaceSpaceWithHyphen(reward.name)}`,
          reward,
          })
      ), [PartnerRewardsList,loadingPartnerRewards]);


  return (
    <div className="d-flex flex-column h-100 flex-fill">
      <Header />
      <div className="home-wrapper d-flex flex-row flex-fill">
        <Navigation />
        <div className="flex-fill">
          <div className="home-content flex-fill d-flex flex-column">
            <Suspense fallback={<LoadingComponent/>}>
              <Routes>
                {/* <Route
                                    path="/refresh"
                                    render={() => {
                                        const url =
                                            qs.parse(location.search, { ignoreQueryPrefix: true })
                                            ?.url || "/";
                                        return <Redirect to={url} />;
                                    }}
                                /> */}
                <Route>
                  <Route name="Home" index element={<HomePage />} />
                  <Route
                    name="Rewards"
                    path="rewards"
                    element={<RewardsPage />}
                  />
                  <Route
                    name="Donations"
                    path="donations"
                    element={<DonationsPage />}
                  />
                  <Route
                    name="CAL Miles"
                    path="cal-miles"
                    element={<CalMilesPage />}
                  />
                  <Route name="About" path="about" element={<AboutPage />} />
                  <Route name="Profile" path="profile/*" element={<ProfilePage />} />
                  {/* <Route
                                    name="Profile"
                                    exact
                                    path="/profile"
                                    component={ProfilePage}
                                /> */}

                  {partnerRewardDynamicNavList && partnerRewardDynamicNavList.map(navItem => {
                        return (
                              <Route
                                key={navItem.name}
                                name={navItem.name}
                                path={navItem.path}
                                element={<CalMilesPage partnerReward={navItem.reward} />}
                            />
                        );
                      })
                  }
                </Route>
              </Routes>
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
