import * as React from "react";

const SvgBadge = (props) => (
  <svg
    viewBox="0 0 19 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#badge_svg__a)">
      <path d="M17.95 7.693a1.953 1.953 0 0 1-.5-1.205 4.24 4.24 0 0 0-3.95-3.951 1.96 1.96 0 0 1-1.206-.499 4.24 4.24 0 0 0-5.588 0c-.333.299-.758.475-1.205.499a4.241 4.241 0 0 0-3.951 3.95c-.024.448-.2.874-.5 1.207a4.242 4.242 0 0 0 0 5.587c.3.333.476.758.5 1.205a4.24 4.24 0 0 0 3.95 3.952c.448.023.873.2 1.206.498a4.24 4.24 0 0 0 5.588.001c.333-.3.758-.476 1.205-.5a4.24 4.24 0 0 0 3.952-3.95c.023-.447.2-.873.498-1.206a4.242 4.242 0 0 0 .001-5.588Zm-4.189 2.236-1.839 1.518a.493.493 0 0 0-.157.507l.634 2.358a.464.464 0 0 1-.696.529l-1.948-1.36a.443.443 0 0 0-.51 0l-1.948 1.36a.463.463 0 0 1-.696-.53l.634-2.357a.492.492 0 0 0-.157-.507L5.24 9.929a.484.484 0 0 1 .266-.856l2.34-.097a.461.461 0 0 0 .413-.313l.812-2.299a.452.452 0 0 1 .86 0l.812 2.299a.461.461 0 0 0 .412.313l2.341.097a.484.484 0 0 1 .266.856Z" />
    </g>
    <defs>
      <clipPath id="badge_svg__a">
        <path transform="translate(0 .987)" d="M0 0h19v19H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgBadge;
