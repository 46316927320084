const RewardSubTypes = {
    INSTANT: "INSTANT",
    PARTNER: "PARTNER",
    VOUCHER: "VOUCHER"
};

const RewardStatus = {
    REQUESTED: "REQUESTED",
    READY: "READY",
    CLAIMED: "CLAIMED",
    CANCELLED: "CANCELLED"
};

const RewardsProcessingStatus = {
    PENDING: "PENDING",
    PROCESSING: "PROCESSING",
    DISPATCHED: "DISPATCHED",
    COMPELTED: "COMPLETED",
    FAILED: "FAILED"
};
const RewardsValidityPeriod = {
    OPEN: "OPEN",
    FIXED: "FIXED",
};

export { RewardSubTypes, RewardStatus, RewardsProcessingStatus,RewardsValidityPeriod };
