const ConfidentialityPolicyData=[
    { header:"OVERVIEW",description:"This Confidentiality Policy has been created to protect companies of the Massy Group and their customers’ confidential information, from exposure to persons outside or inside the organization. Persons could intentionally or inadvertently use the information in a way that could be detrimental to the organization or the client. Such information will hereinafter be referred to as the “Confidential Information.” A Confidentiality Policy further protects persons, authorized to have access to the Confidential Information, by clarifying and specifying, their duties and obligations regarding the protection of the Confidential Information."},
    { header:" PURPOSE",description:"The purpose of this policy is to outline the measures taken by Massy to ensure the protection of Confidential Information."},
    { header:"SCOPE",description:"In the course of their work, employees and independent contractors may receive, view, or interact with information including sensitive personal information and/or company Confidential Information. Disclosure of Confidential Information to unauthorized parties can do irreparable harm to any Massy Group company and by extension the Massy Group. It is in the best interest of the Massy Group to specify guidelines for persons receiving, viewing, listening to, or using Confidential Information. It is required that such persons fulfill a Contractual Agreement acknowledging intent to comply with such guidelines."},
    { header:"INFORMATION PROTECTED UNDER THE CONFIDENTIALITY POLICY",description:"Confidential Information may consist of trade secrets, customer personal information, intellectual property, and other proprietary data developed, created, gathered, or used in the course of doing business. Confidential Information includes, but is not limited to financial, sales, marketing, operational, legal, personnel, and production information. Confidential Information also includes proprietary or confidential information of any third party that may disclose such information to either party in the course of the other party’s business. Confidential Information may be communicated in written, spoken, graphical, visual, auditory, or other form and may be presented or stored in paper, magnetic, electronic, physical model, or other formats."},
    { header:"ACTIONS PROHIBITED",description:"Persons receiving Confidential Information are prohibited from making use of, distributing, or in any way disclosing any Confidential Information to any person, firm, or business unless it is necessary for conducting business in the interest of the relevant Massy Group company. Any use of such information in conducting business must be approved by the relevant authorized personnel of the company. Persons receiving Confidential Information are prohibited from copying, capturing, or storing in any form the Confidential Information without permission from the respective Massy Group company’s Manager or supervisor, in accordance with the delegation of authority. Persons receiving Confidential Information are mandated to hold same in confidence and take all reasonable precautions to protect and secure the Confidential Information. Persons accidentally receiving unsolicited Confidential Information are required to immediately give notice to the supervisor or manager about any unauthorized use or disclosure of the Confidential Information. They must also agree to assist the organization in remedying any such unauthorized use or disclosure of the Confidential Information."},
    { header:"VIOLATIONS",description:"Any violations of the Confidentiality Policy must be reported immediately to the relevant ICT department and the employee’s manager. Violating this policy or any of its tenets could result in disciplinary action."},
    { header:"ENFORCEMENT",description:"Massy will enforce the Security Policy Framework and establish standards, procedures, and protocols in support of the policy. Any employee found to have violated this policy may be subject to disciplinary action. It is the responsibility of the Users to read, understand and comply with the various matters set out in this policy."},
    { header:"MODIFICATION TO THIS POLICY",description:"Please note that from time to time this policy will be reviewed and changed to reflect IT standards and best practices, worldwide. If you are in any doubt as to which laws, regulations, codes of conduct, and company guidance are relevant to your situation you should seek advice from your supervisor, HR representative, or legal department."},
    { header:"ACKNOWLEDGMENT OF THE CONFIDENTIALITY POLICY",description:"This form is used to acknowledge receipt of and compliance with the company’s Confidentiality Policy."},
]

export {ConfidentialityPolicyData}
