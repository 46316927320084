import React from "react";

const TrinidadFAQ =[
    {question:"What is the fee to sign up for the Massy Card?",answer:"There are no fees."},
    {question:"How can I sign up for a Massy Card?",answer:<>Simply apply by completing the Massy Card Application form available in-store at all participating Massy merchants, online at <a href='http://www.massycard.com/'>www.massycard.com</a>  or from the Massy Stores App which you can download from the Google Play Store™ or the Apple App Store™</>},
    {question:"Where can I get the Massy Stores App?",answer:<>{"At the Google Play Store™ and the Apple App Store™"} <a href='http://www.massycard.com/'>www.massycard.com</a></>},
    {question:"How do I get a Massy Card if I currently have a Hi-Lo Smart Shopper Card?",answer:"Visit your regular Massy Store and speak with the in-store Massy Card Representative there and they will help you get your new Card."},
    {question:"Will all my points transfer from my temporary Massy Card when I upgrade to the permanent Massy Card?",answer:"Yes, all points will be transferred the day after you collect your new Massy Card."},
    {question:"Does the new Massy Card have the same number as my current Smart Shopper Card?",answer:"No, it does not. Under the new Massy Card program, Customers will be assigned new Massy Card account numbers."},
    {question:"How can I sign up for a Massy Card?",answer:<>{"Simply apply by completing the Massy Card Application form available in-store at all participating Massy merchants, online at"} <a href='http://www.massycard.com/'>www.massycard.com</a> {"or from the Massy Card App which you can download from the Google Play Store™ or the Apple App Store™"}</>},
    {question:"Who is eligible to apply for a Massy Card?",answer:"You must be sixteen years (16) or older to be eligible to sign up for a Massy Card."},
    {question:"I signed up today for my Massy Card, when will I get it?",answer:"If you sign up in-store, you will immediately get a Key Ring Massy Card that you can start using right away to earn and redeem points. If you sign up on-line, you must go to\n" +
            "a Massy location that issues Massy Cards to complete your registration process and receive a Key Ring Massy Card."},
    {question:"Can someone else collect my Massy Card for me?",answer:"Yes, they just need to have a letter of authorization and a copy of the ID you used to register."},
    {question:"I have an embossed Massy Card, but I want a Key Ring card – how can I get one?",answer:"Speak to one of our in-store Massy Card Representatives and make a request for a Key Ring card. There will be a 50-point fee to order a Key Ring Card if you are an existing card holder. Key Ring Cards take 6-8 weeks to process."},
    {question:"What do I need to do to earn Massy points?",answer:"All you need to do is to present your Massy Card at any one of the participating Massy merchants."},
    {question:"How much do I need to spend to get a Massy point?",answer:"One Massy point is earned for every qualifying $10 spent."},
    {question:"Can I have a joint card with someone?",answer:"Yes you can! Members of your immediate family living in the same household or wards can be secondary card holders. When applying, the primary card holder must complete and sign the bottom section of the form section of the form authorizing a secondary card holder on their account."},
    {question:"Can both of us earn and redeem points?",answer:"Both can earn but only the primary card holder is set as a redeemer. One form of ID must be shown to redeem. All points earned accumulate in the primary card holder’s account."},
    {question:"Where can I earn and redeem points?",answer:<>
            <p>Here’s where you can earn points:</p>
            <span>Massy Stores</span>
            <br/>
            <span>Massy Motors</span>
            <p className='mt-2'>Here’s where you can redeem points at this time:</p>
            <span>Massy Stores</span>
            <br/>
            <span>Massy Motors</span>
    </>},
    {question:"What can I redeem?",answer:<>
            <p>Massy Stores:</p>
            <span>Massy Stores Gift Cards for the purchase of groceries, pharmaceutical items, and small appliances</span>
            <br/>
            <span>Caribbean Miles</span>
            <p className='mt-2'>Massy Motors:</p>
            <span>New and pre-owned vehicles</span>
            <br/>
            <span>Vehicle spare parts</span>
            <br/>
            <span>Service</span>
        </>},
    {question:"How can I earn bonus points and double points?",answer:"Each participating Massy merchant will run special promotions during the year, for example, double points on a Wednesday at Massy Stores. Also, both primary and secondary cardholders can get double points on their birthdays at Massy Stores and Massy Motors."},
    {question:"How soon can I use my points after I earn them?",answer:"Your points from all your purchases at participating Massy merchants will be available to you within 24 hours except for double points on birthdays which may take up to 48 hours."},
    {question:"Do my points expire?",answer:"Yes, the program runs on a 15-month rolling expiry. This means if your points are not used 15 months after being earned, they will expire."},
    {question:"How can I check to see if my points are expiring?",answer:"We will let you know! An email will be sent informing you that there are points set to expire on your account. In addition, you can keep track of your points by scanning your card at any one of the Massy Stores in-store price checkers, create an online account at http://portaltt.massycard.com or contact us at 489-3559 or 737-1669 or via email at info.tt@massycard.com"},
    {question:"Do I need a minimum number of points before I can redeem?",answer:"Yes. You must have at least 400 Massy points to redeem. For CAL Miles Redemptions, you need a minimum of 600 Massy points to redeem."},
    {question:"Is there a ceiling for my points, do I have to redeem when I reach a certain amount?",answer:"No, you can earn as much points as you want."},
    {question:"Do I have to present my Massy Card when redeeming points?",answer:"Yes you must present your physical or digital Massy Card to get the number of your account. You also need to show ID so we can ensure you are the person redeeming your points."},
    {question:"If my Card is lost or stolen can someone else redeem my points?",answer:"To redeem points, ID must be shown to verify the redeemer is the card holder."},
    {question:"If my Card is lost how do I get a new one and is there a cost?",answer:"There is a 50-point fee to replace a lost card. If this happens immediately contact one of our Massy Card Representatives in-store or the Massy Card Call Centre at 489-3559 or 737-1669 and they will assist you."},
    {question:"Who is eligible to get discounts when using their Massy Card?",answer:"Employees of the Massy Group are eligible for special discounts at selected participating Massy merchants. Registered TTARP members are eligible for discounts at Massy Stores only. Secondary cardholders are eligible for the same discount as their associated primary account at Massy Stores only."},
    {question:"Can I get a discount and get points on the same purchase?",answer:"Cardholders who get discounts will receive points based on the qualifying value of the purchase."},
    {question:"Who can be contacted for more information?",answer:"Any Massy Card Representative at the Massy Card Call Centre at 489-3559 or 737-1669 or at the participating Massy merchants or email us at info.tt@massycard.com."},
    {question:"I already have a Hi-Lo Smart Shopper Card, do I have to change to the Massy Card?",answer:"Yes you do. Speak with any Massy Card Representative in-store at one of the participating Massy Merchants or call at the Massy Card Call Centre at 489-3559 or 737-1669 from 8 am to 4 pm Monday to Friday and they will help you."},
    {question:"Can I continue to use my Hi-Lo Smart Shopper Card after the Massy Card comes out?",answer:"All Smart Shopper Cards will remain active for a period after the launch of the Massy Card. You are encouraged to change your Smart Shopper Card for a Massy Card at your earliest convenience as all Smart Shopper Cards will become inactive soon. Once you collect your Massy Card, your Smart Shopper Card will be deactivated in 4 weeks. This is to allow time for family members that are Secondary Card Holders on your Smart Shopper account to get a new Massy Card and have it linked to your new Massy Card."},
]

export {TrinidadFAQ}
