import * as React from "react";

const SvgAirline = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M2.5 19h19v2h-19v-2Zm19.57-9.365a1.497 1.497 0 0 0-1.835-1.06L14.92 10l-6.9-6.435-1.93.52 4.14 7.175-4.97 1.33-1.965-1.545-1.45.39 1.82 3.155.765 1.325 1.605-.43 5.315-1.425 4.345-1.165 5.315-1.425a1.497 1.497 0 0 0 1.06-1.835Z" />
    <path d="M2 19h20v2H2z" />
  </svg>
);

export default SvgAirline;
