import React,{useContext,useMemo} from "react";
import {Container, Offcanvas, Nav, Navbar, Image} from "react-bootstrap";
import MassyLogo from "../../../assets/images/massy-logo.png";
import './OffCanvas.scss'
import LinkComponent from "../../shared/linkComponent/LinkComponent";
import {Airline, Badge, Donation, Info, Tile} from "../../icons/Shoutout";
import { UserContext } from "Contexts";
import {LoadingComponent} from "../../utils";
import { toReplaceSpaceWithHyphen, toTitleCase } from "Utils";
import {useToggle} from "../../../Hooks";

const navList = [
    {
        tab: "Home",
        path: "/home",
        icon: Tile,
    },
    {
        tab: "Rewards",
        path: "/home/rewards",
        icon: Badge
    },
    {
        tab: "Donations",
        path: "/home/donations",
        icon: Donation,
    }
];


const OffCanvas =()=>{
    const { PartnerRewardsList,loadingPartnerRewards } = useContext(UserContext);
    const [show, toggleShow] = useToggle(false);
    const partnerRewardDynamicNavList = useMemo(() =>{
        if(PartnerRewardsList&& !loadingPartnerRewards){
            return navList.concat(PartnerRewardsList.map(reward => ({
                    tab: toTitleCase(reward.name),
                    path: `/home/${toReplaceSpaceWithHyphen(reward.name)}`,
                    icon: reward.imageUrls.length===0?reward.imageUrls[0]:Airline,
                })
            ),[{
                tab: "About",
                path: "/home/about",
                icon: Info,
            }])
        }
    }, [PartnerRewardsList,loadingPartnerRewards]);

    return(
        <Navbar bg="light" expand={false} className='off-canvas'>
            <Container fluid>
                <Navbar.Toggle aria-controls="offcanvasNavbar"  onClick={toggleShow}/>
                <Navbar.Offcanvas
                    id="offcanvasNavbar"
                    aria-labelledby="offcanvasNavbarLabel"
                    placement="end"
                    className='off-canvas'
                    onHide={toggleShow}
                    show={show}
                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title id="offcanvasNavbarLabel">
                            <Image
                                className="off-canvas-logo"
                                src={MassyLogo}
                                alt="donation"
                            />
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav activeKey className="justify-content-end flex-grow-1 pe-3">
                            {partnerRewardDynamicNavList &&
                            <>
                                {loadingPartnerRewards ?
                                    <LoadingComponent/>
                                    :
                                    partnerRewardDynamicNavList.map(navItem => {
                                        return (
                                            <LinkComponent
                                                path={navItem.path}
                                                onClick={toggleShow}
                                                icon={navItem.icon}
                                                name={navItem.tab}
                                                key={navItem.tab}
                                                className="mb-3"
                                            />
                                        );
                                    })
                                }
                            </>
                            }
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    )
}

export default OffCanvas
