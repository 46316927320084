import React from 'react';
import PropTypes from 'prop-types';
import './ShIcon.scss';

const ShIcon = ({ icon: Icon, size, className ,...rest}) => {
    return (<Icon className={`sh-icon ${size ? `icon-${size}` : ''} ${className ? className : ''}`} {...rest}/>)
}

ShIcon.defaultProps = {

    // size: "sm", // xs, sm, lg, 1_3x, 2x, 3x,.....9x, 10x

}
ShIcon.propTypes = {
    /*
    * ShoutOUT icon
    */
    icon: PropTypes.func.isRequired,
    size: PropTypes.oneOf(['xs', 'sm', 'lg', '2x', '3x', '4x']),
    className: PropTypes.string

}
export default ShIcon;