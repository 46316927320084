import { fetchGet, fetchPost, fetchPut, preProcessBodyData } from './CommonServiceUtils';
import Constants from '../Constants';

const BASE_URL = `${Constants.BASE_URL}loyaltyservice/portal/accounts`;

const validateCardNo = payload => {
  return fetchPost(`${BASE_URL}/validatecard`, payload)
};

const getVerifyTokenExisting = payload => {
  return fetchPost(`${BASE_URL}/verifytokenexisting`, payload)
};

const getVerifyTokenNew = (payload, channel) => {
  return fetchPost(
    `${BASE_URL}/verifytokennew?channel=${channel}`,
    preProcessBodyData(payload)
  )
};

const verifyAccount = payload => {
  return fetchPost(`${BASE_URL}/verify`, payload)
};

const createAccount = payload => {
  return fetchPost(`${BASE_URL}`, payload)
};

const getAccountDetails = () => {
  return fetchGet(`${BASE_URL}/profile`)
};

const deactivateCard = payload => {
  return fetchPost(`${BASE_URL}/deactivatecard`, payload)
};
const updateAccountDetails = (payload) => {
  return fetchPut(`${BASE_URL}`, preProcessBodyData(payload))
};

const requestSecondaryAddToken = payload => {
  return fetchPost(`${BASE_URL}/secondaryaddrequest`, payload)
};

const addSecondary = payload => {
  return fetchPost(`${BASE_URL}/addsecondary`, payload)
};

const LinkANewCard = payload => {
  return fetchPost(`${BASE_URL}/linkcard`, payload)
};

const removeSecondary = payload => {
  return fetchPost(`${BASE_URL}/removesecondary`, payload)
};

const exportData = () => {
  return fetchPost(`${BASE_URL}/export`)
}

const deleteMyAccountData = () => {
  return fetchPost(`${BASE_URL}/erase`)
}

export {
  validateCardNo,
  getVerifyTokenExisting,
  getVerifyTokenNew,
  verifyAccount,
  createAccount,
  getAccountDetails,
  updateAccountDetails,
  requestSecondaryAddToken,
  addSecondary,
  removeSecondary,
  exportData,
  deleteMyAccountData,
  LinkANewCard,
  deactivateCard
};
