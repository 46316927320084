import * as React from "react";

const SvgUser = (props) => (
  <svg
    viewBox="0 0 33 36"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.44 24.817c-1.739-2.468-3.981-3.744-7.77-4.154-.685-.15-1.2-.808-1.2-1.59 0-.572 1.69-2.286 1.678-2.299 1.745-1.96 2.681-5.145 2.681-7.593 0-3.804-2.818-6.891-6.296-6.891s-6.296 3.087-6.296 6.89c0 2.463.911 5.667 2.674 7.627 0 0 1.685 1.694 1.685 2.266 0 .828-.576 1.518-1.32 1.616-3.711.423-5.93 1.693-7.65 4.129-.498.703-.768 2.116-.786 2.872.006.195 0 3.256 0 3.256 0 1.44 1.073 2.605 2.398 2.605h18.59c1.324 0 2.398-1.165 2.398-2.605 0 0-.006-3.06 0-3.256-.018-.756-.288-2.17-.786-2.873Z"
    />
  </svg>
);

export default SvgUser;
