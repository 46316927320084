import React,{useContext,useMemo} from "react";
import { Nav, Button } from "react-bootstrap";
import { Tile, Badge, Donation, Airline, Info } from "Components/icons/Shoutout";
import HelpBanner from "assets/images/help-banner.png";
import { SubTitle } from "Components/utils";
import { UserContext } from "Contexts";
import {LoadingComponent} from "../utils";
import LinkComponent from "Components/shared/linkComponent/LinkComponent";
import {useToggle} from "../../Hooks";
import { toReplaceSpaceWithHyphen, toTitleCase } from "Utils";
import GetHelp from "./getHelp/GetHelp"

import "./Navigation.scss";


const navList = [
  {
    tab: "Home",
    path: "/home",
    icon: Tile,
  },
  {
    tab: "Rewards",
    path: "/home/rewards",
    icon: Badge
  },
  {
    tab: "Donations",
    path: "/home/donations",
    icon: Donation,
  }
];

const Navigation = () => {
  const { PartnerRewardsList,loadingPartnerRewards } = useContext(UserContext);
  const [show, setShow] = useToggle(false);
  const partnerRewardDynamicNavList = useMemo(() =>{
    if(PartnerRewardsList&& !loadingPartnerRewards){
      return navList.concat(PartnerRewardsList.map(reward => ({
            tab: toTitleCase(reward.name),
            path: `/home/${toReplaceSpaceWithHyphen(reward.name)}`,
            icon: reward.menuIcon?reward.menuIcon:Airline,
          })
      ),[{
        tab: "About",
        path: "/home/about",
        icon: Info,
      }])
    }
  }, [PartnerRewardsList,loadingPartnerRewards]);


  return (
    <div className="sidebar-wrapper d-none d-lg-block">
      <div className="side-navigation h-100 d-flex flex-column justify-content-start">
        <Nav activeKey className="d-flex flex-column">
          {partnerRewardDynamicNavList &&
          <>
            {loadingPartnerRewards ?
                <LoadingComponent/>
                :
                partnerRewardDynamicNavList.map(navItem => {
                  return (
                      <LinkComponent
                          path={navItem.path}
                          icon={navItem.icon}
                          name={navItem.tab}
                          key={navItem.tab}
                          className="mb-3"
                      />
                  );
                })
            }
          </>
          }
        </Nav>
        <div className="help-container">
          <SubTitle className="fw-bold">Need Help?</SubTitle>
          <p><small>Thank you for your interest in joining our Massy Card Loyalty Program!</small></p>
          <Button size="sm" variant="primary" onClick={setShow}>Get Help</Button>
          <div className="align-right">
            <img src={HelpBanner} alt="help" className="help-banner"/>
          </div>
        </div>
        {/* <Nav activeKey className="d-flex flex-column">
          <LinkComponent path={"/home/settings"} icon={CogWheel} name={"Settings"} />
        </Nav> */}
      </div>
      {show?<GetHelp
          show={show}
          handleClose={setShow}
      />:null}
    </div>
  );
};

export default Navigation;
