import * as React from "react";

const SvgDonation = (props) => (
  <svg
    viewBox="0 0 20 19"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.4}
      d="M19.25 10.49a1.484 1.484 0 0 1-1.489-1.476c0-.815.668-1.477 1.489-1.477.199 0 .39-.078.53-.217a.74.74 0 0 0 .22-.526l-.001-2.677c0-2.263-1.859-4.104-4.143-4.104H4.144C1.86.013.001 1.853.001 4.117L0 6.88a.74.74 0 0 0 .22.527c.14.138.331.217.53.217.849 0 1.489.597 1.489 1.39 0 .813-.668 1.475-1.489 1.475a.747.747 0 0 0-.75.743v2.676c0 2.263 1.858 4.105 4.143 4.105h11.714c2.285 0 4.143-1.842 4.143-4.105v-2.676a.747.747 0 0 0-.75-.743Z"
    />
    <path d="M13.43 8.6 12.253 9.75l.279 1.623a.732.732 0 0 1-.296.724.73.73 0 0 1-.78.055L10 11.386l-1.458.766a.737.737 0 0 1-.343.087.738.738 0 0 1-.434-.142.736.736 0 0 1-.295-.725l.278-1.623L6.568 8.6a.737.737 0 0 1 .413-1.262l1.627-.237.729-1.477A.738.738 0 0 1 10 5.213h.002a.736.736 0 0 1 .662.413l.729 1.476 1.629.238a.732.732 0 0 1 .596.5.73.73 0 0 1-.187.76Z" />
  </svg>
);

export default SvgDonation;
