import React, { useContext, useEffect } from 'react'
import { UserContext } from './Contexts/userContext'
import { LoadingComponent } from 'Components/utils'

const AuthRoute = ({ children }) => {
  const { isAuth, login,initialized } = useContext(UserContext)

  useEffect(() => {
    if (!isAuth&&initialized) login()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth,initialized])
  return isAuth&&initialized ? children : <LoadingComponent />
}

export default AuthRoute
