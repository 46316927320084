import { fetchGet, fetchPostMultipart, jsonToQueryParam } from "./CommonServiceUtils";
import Constants from "../Constants";

const BASE_URL = `${Constants.BASE_URL}loyaltyservice/portal/`;

const getMerchants = ({limit=1000,skip=0}) => {
    return fetchGet(`${BASE_URL}merchants?${jsonToQueryParam({limit,skip})}`);
};

const getTiers = ({limit=1000,skip=0}) => {
    return fetchGet(`${BASE_URL}tiers?${jsonToQueryParam({limit,skip})}`);
};

const getPointRules = ({limit=1000,skip=0}) => {
    return fetchGet(`${BASE_URL}pointrules?${jsonToQueryParam({limit,skip})}`);
};

const getRegionalPointConfiguration= () => {
    return fetchGet(`${BASE_URL}regions`);
};

const getTransactions = payload => {
    return fetchGet(`${BASE_URL}transactions?${jsonToQueryParam(payload)}`);
};

const uploadImage = file => {
    const formData = new FormData();
    formData.append("file", file);
    return fetchPostMultipart(`${BASE_URL}imageuploads`, formData);
};

export { 
    getMerchants,
    getTiers,
    getPointRules,
    getRegionalPointConfiguration,
    getTransactions,
    uploadImage
};
