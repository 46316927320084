import * as React from "react";

const SvgPlusCircle = (props) => (
  <svg
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={18} cy={18} r={17.5} stroke="currentColor" />
    <path d="M25 19h-6v6h-2v-6h-6v-2h6v-6h2v6h6v2Z" fill="currentColor" />
  </svg>
);

export default SvgPlusCircle;
