import React,{useContext}from "react";
import { Row,Col,Modal,Image } from 'react-bootstrap';
import {UserContext} from "../../../Contexts";
import customerCare from '../../../assets/images/customer-care.svg';
import "./GetHelp.scss"

const GetHelp=({show,handleClose})=>{
    const {regionConfig} = useContext(UserContext);
    return(
        <Modal show={show} onHide={handleClose} centered size="lg">
                <Modal.Body closeButton className='get-help' >
                    <Row xl="12" lg="12" md="12" sm="12" xs="12">
                        <Col xl="6" lg="6" md="6" sm="6" xs="12">
                            <Row xl="12">
                                <Col xl="12">
                                    <span className="main-text">Get Help</span>
                                </Col>
                                {regionConfig.supportInfo?
                                  <>
                                      <Col xl="12" className="p-3">
                                          <Col xl="12">
                                              <span className="contact-name">Email:</span>
                                          </Col>
                                          <Col xl="12" >
                                              <span className="contact-info">
                                                  {regionConfig.supportInfo.email? regionConfig.supportInfo.email:"-"}
                                              </span>
                                          </Col>
                                      </Col>
                                      <Col xl="12" className="p-3">
                                          <Col xl="12">
                                              <span className="contact-name">Phone Numbers:</span>
                                          </Col>
                                          <Col xl="12" >
                                              {regionConfig?.supportInfo?.phoneNumbers?.length!==0?
                                                <>
                                                {regionConfig?.supportInfo?.phoneNumbers.map((number,index)=>
                                                    <span className="contact-info" id={index}>{number+((regionConfig?.supportInfo?.phoneNumbers?.length-1)!==index?", ":"")}</span>
                                                )}
                                                </>:"-"
                                              }
                                          </Col>
                                      </Col>
                                      <Col xl="12" className="p-3">
                                          <Col xl="12">
                                              <span className="contact-name">Whatsapp Number:</span>
                                          </Col>
                                          <Col xl="12" >
                                              {regionConfig.supportInfo.whatsappNumber? <span className="contact-info">{regionConfig.supportInfo.whatsappNumber}</span>:"-"}
                                          </Col>
                                      </Col>
                                  </>:<div>data not found</div>
                                }
                            </Row>
                        </Col>
                        <Col  xl="6" lg="6" md="6" sm="6" xs="12" className="d-flex justify-content-end" >
                            <Image
                                className="main-image"
                                src={customerCare}
                                alt="Paris"
                            />
                        </Col>
                    </Row>
                </Modal.Body>
        </Modal>
    )
}
export default GetHelp
