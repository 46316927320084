import React, { useContext } from "react";
import { LinkContainer } from "react-router-bootstrap";
import {Nav, Dropdown, Image} from "react-bootstrap";
import Avatar from 'react-avatar';
import OffCanvas from '../offcanvas/OffCanvas'
import MassyCardLogo from "../../../assets/images/massy-card-logo.svg";
import { UserContext } from "Contexts";

import "./Header.scss";


const Header = () => {
  const {username,selectedRegion,logout,profilePicture}=useContext(UserContext);
  return (
            <div className="header d-flex flex-row align-items-center">
              <div className="logo-container d-flex justify-content-center">
                <Image src={MassyCardLogo} className='logo' alt="logo" />
              </div>
                <div className="flex-fill px-4">
                <div className="align-items-center d-flex justify-content-between flex-row">
                  <div>{/*SEARCH*/}</div>
                  <div className="align-items-center d-flex flex-row">
                    {selectedRegion.regionIconUrl&&<Avatar size="2rem" round={true} src={selectedRegion.regionIconUrl}/>}
                    <div>
                    <Dropdown className="profile-selector">
                    <Dropdown.Toggle variant="link" id="dropdown-profile" className="text-decoration-none">
                        <div className='d-flex'>
                            <Avatar size="2rem" name={username} src={profilePicture} round={true} className="me-1"/>
                            <span className="m-1 text-dark d-none d-lg-block">{username}</span>
                        </div>
                    </Dropdown.Toggle>
                          <Dropdown.Menu className="w-100 mt-3 rounded">

                            <LinkContainer to={"/home/profile"}>
                                    <Nav.Link>
                                   My Profile
                                    </Nav.Link>
                                  </LinkContainer>

                        <Dropdown.Item onClick={logout} as={Nav.Link}>Logout</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                  </div>
                  </div>
                </div>
              </div>
              <div className='d-lg-none'>
                  <OffCanvas/>
              </div>
            </div>
  );
};

export default Header;
