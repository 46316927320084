import React from "react";
import {Image} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import connectionLost from '../src/assets/images/connection-lost.svg';
import "./ErrorBoundary.scss";
import '../src/Components/landingPage/headerSection/HeaderSection.scss'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
    this.handleOnClick = this.handleOnClick.bind(this)
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });

    // Log error info somewhere
  }
   handleOnClick() {
    this.props.history('home')
    window.location.reload()
  }
  render() {
    if (this.state.errorInfo) {
      return <div className='error-boundary'>
              <Image
                  className="connection-lost"
                  src={connectionLost}
                  alt="Paris"
              />
              <span className='main-text'> Oops! Something went wrong..</span>
              <button className="custom-btn btn-3 me-3" onClick={this.handleOnClick}>
                <span>TAKE ME HOME</span>
              </button>
            </div>;
    }
    return this.props.children;
  }
}

const WrappedComponent = props => {
  let navigate = useNavigate();
  return <ErrorBoundary history={navigate}  {...props} />
}
export default WrappedComponent ;
