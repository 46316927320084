import ShIcon from "Components/icons/ShIcon";
import React from "react";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const LinkComponent = ({  path, icon, name,eventKey, ...rest }) => {
    return (
        <LinkContainer to={path} {...rest}>
            <Nav.Link eventKey={eventKey}>
                {icon && <ShIcon icon={icon} className="me-2" />} {name}
            </Nav.Link>
        </LinkContainer>
    );
};

export default LinkComponent;
