import * as React from "react";

const SvgMinusCircle = (props) => (
  <svg
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={18} cy={18} r={17.5} stroke="currentColor" />
    <path fill="currentColor" d="M10 17h16v2H10z" />
  </svg>
);

export default SvgMinusCircle;
