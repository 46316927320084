import React from 'react';


const SubTitle = ({children,className,...rest}) => {
    return (
        <div className={`${className||''} subtitle`} {...rest}>
            {children}
        </div>
    );
};


export default SubTitle;