import * as React from "react";

const SvgTile = (props) => (
  <svg viewBox="0 0 20 21" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity={0.4}
      d="M14.076.013h3.386A2.549 2.549 0 0 1 20 2.573v3.414a2.549 2.549 0 0 1-2.538 2.56h-3.386a2.549 2.549 0 0 1-2.539-2.56V2.573a2.549 2.549 0 0 1 2.539-2.56Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.539.013h3.385a2.549 2.549 0 0 1 2.539 2.56v3.414a2.549 2.549 0 0 1-2.539 2.56H2.54A2.549 2.549 0 0 1 0 5.987V2.573A2.549 2.549 0 0 1 2.539.013Zm0 11.465h3.385a2.549 2.549 0 0 1 2.539 2.56v3.415a2.55 2.55 0 0 1-2.539 2.56H2.54A2.55 2.55 0 0 1 0 17.453v-3.415a2.549 2.549 0 0 1 2.539-2.56Zm14.923 0h-3.386a2.549 2.549 0 0 0-2.539 2.56v3.415a2.55 2.55 0 0 0 2.538 2.56h3.387A2.55 2.55 0 0 0 20 17.453v-3.415a2.549 2.549 0 0 0-2.538-2.56Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgTile;
