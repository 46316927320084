import StepOne from "../assets/images/stepOne.svg";
import StepTwo from "../assets/images/stepTwo.svg";
import StepThree from "../assets/images/stepThree.png";
import StepFour from "../assets/images/stepFour.png";
import Rain from "../assets/images/rain.svg";
import TeamSpirit from "../assets/images/team-spirit.svg";
import PeopleCelebrate from "../assets/images/people-celebrate.svg";
import MobileLogin from "../assets/images/mobile-login.svg";
import BirthdayCandles from "../assets/images/birthday-candles.svg";

import Redeem from "../assets/images/redeem-for-bill.svg";
import StoreLocations from "../assets/images/store-locations.svg";
import SharePoints from "../assets/images/share-points.svg";
import CaribbeanMiles from "../assets/images/caribbean-miles.svg";
import DonatePoints from "../assets/images/donate-points.png";
import RewardsPool from "../assets/images/rewards-pool.svg";

import Standard from "../assets/images/standard.svg";
import Bronze from "../assets/images/bronze.svg";
import Silver from "../assets/images/silver.svg";
import Gold from "../assets/images/gold.svg";

import MassyStores from "../assets/images/partners/massy-stores.svg";
import FerreiraOptical from "../assets/images/partners/ferreira-optical.png";
import MassyMotors from "../assets/images/partners/massy-motors.png";
//import MassyUnited from "../assets/images/partners/massy-united.png";
import MassyFinance from "../assets/images/partners/massy-finance.png";
//import Amplia from "../assets/images/partners/amplia.png";
import CaribbeanAirlines from "../assets/images/partners/caribbean-airlines.png";
import massyAutomotiveComponentsLimited from "../assets/images/partners/massy-automotive-components-limited.png";
import Trinpad from "../assets/images/partners/trinpad.png";



import {TrinidadFAQ} from "./FAQData";



const MassyLoyaltyCardData = {
        textOne: " ",
        textOneColor:"TEXT-DARK",
        textTwo:'Getting Started With the ',
        applyBackground:false,
        textThree:"Massy Card",
        descriptionOne: "Swipe, Earn & Save on your transactions.\n" +
            "Redeem your Massy Points for amazing rewards, offers and instant discounts.",
        descriptionThree: {
         headerOne:"It's Easy to Save With Your ",
         headerTwo:"Massy Card"
        },
        descriptionType:"TYPE-THREE",
        columnSize:3,
        CardType:"background-blue",
        commonCardContent:[
                           {image:StepOne,headerClass:"step-one",commonCardHeaderStep:"Step One: ", header:"Sign Up for Your Free Massy Card", description:<>
                                   Visit any Massy Stores location or participating partner and pick up a Key Tag to start earning immediately. You can also sign up via our customer portal and instantly receive your digital Massy Card.
                                   <br/>
                                   <a href="/signup">Click here to sign up</a>
                               </>},
                          {image:StepTwo,headerClass:"step-two",commonCardHeaderStep:"Step Two: ", header:"Add a Secondary Massy Cardholder to One Account", description:"Add a family member or loved one to your Massy Card account so you can both earn points and save together!"},
                          {image:StepThree,headerClass:"step-three",commonCardHeaderStep:"Step Three: ", header:"Swipe & Earn", description:"Swipe your Massy Card or Key Tag in-store to start earning Massy Points on your purchases."},
                          {image:StepFour,headerClass:"step-four",commonCardHeaderStep:"Step Four: ", header:"Save & Enjoy", description:"Enjoy many ways to redeem your Massy Points. Redeem instantly instore on your purchase, gift vouchers, promotional items or Caribbean Miles. You may also gift your Massy Points to a partner charity."}
        ]

    }
const MassyPointData = {
    textOne: " ",
    textOneColor:"TEXT-DARK",
    textTwo:'So Many Ways to Earn ',
    textThree:"Massy Points!",
    descriptionOne: "There are so many ways to earn Massy Points with Massy Stores and partners.\n" +
        "Earning points is easy.  Here's how...",
    columnSize:3,
    CardType:"background-white",
    applyBackground:true,
    commonCardContent:[
        {},
        {},
        {image:Rain,headerClass:"every-dollar", header:"For every dollar you spend", description:"Earn as you make purchases at Massy Stores and partners."},
        {image:TeamSpirit, header:"For special groups", description:"Earn points for being apart of special groups."},
        {},
        {},
        {image:PeopleCelebrate, header:"For special seasons", description:"Earn points for purchases during special seasons."},
        {image:BirthdayCandles,headerClass:"your-birthday", header:"On your birthday", description:"Earn Massy Points as a gift from Massy for your birthday."},
        {},
        {},
        {image:MobileLogin, header:"Earn when you roam", description:"Earn Massy Points across the Caribbean when you shop at Massy Stores Barbados,St. Lucia, St. Vincent and Guyana."},
        {image:StoreLocations, header:"Shop on wednesdays", description:"Earn DOUBLE Massy Points when you shop at Massy Stores."},
    ]
}
const MassyRedeemPointData = {
    textOne: 'Redeem Your Way!',
    descriptionOne: "Massy Cardholders can enjoy many exclusive and exciting options to redeem Massy Points, from both Massy Stores and its partners.",
    columnSize:4,
    descriptionTwo: "*Special conditions apply*",
    descriptionType:"TYPE-ONE",
    CardType:"background-white",
    applyBackground:false,
    commonCardContent:[
        {image:Redeem, header:"Redeem In-Store", headerClass:"common-class", subheader:'Pay your Massy Points', description:"Easily pay your bill with Massy Points when you shop at Massy Stores and other partners."},
        {image:DonatePoints,headerClass:"common-class", header:"Support Your Community",subheader:'Convert points to donations',description:"Donate your Massy Points to a partner charity and support a worthy cause."},
        {image:RewardsPool,headerClass:"reward-pool", header:"Reward Pool",subheader:'Get that gift you’ve always wanted',  description:"There are amazing rewards and\n" +
                "gifts waiting just for you! \n" +
                "Click here to see what you can \n" +
                "purchase with your Massy points."},
        {image:CaribbeanMiles,headerClass:"common-class", header:"Caribbean Miles",subheader:'Earn Caribbean Miles with ease', description:"Convert your points into Miles and earn\n" +
                "your dream trip! No extra effort, it just \n" +
                "comes with your day to day Massy\n" +
                "purchases."},
        {image:SharePoints,headerClass:"share-points", header:"Share Points",subheader:'Gift your points to loved ones',description:"Transfer your earned points as a gift to \n" +
                "your family, and friends who are Massy \n" +
                "Loyalty primary account holders."},
    ]
}
const MassyTierData = {
    textOne: 'Earn More Points to Level Up',
    descriptionOne: "The more points you collect as you make purchases at Massy, the higher you can go in Massy loyalty tiers. Starting from the Standard tier, go higher with more points to Bronze, Silver, and finally, Gold. The countless \n" +
        "benefits for each tier get better as you go higher.",
    columnSize:3,
    CardType:"background-white-second",
    applyBackground:false,
    commonCardContent:[
        {image:Standard, header:"Standard", description:"0 -100 Points"},
        {image:Bronze, header:"Bronze",description:"101-200 Points"},
        {image:Silver, header:"Silver",description:"201-500 Points"},
        {image:Gold, header:"Gold", description:"501-1000 Points"},
    ]
}

const MassyPartnerData = {
    textOne: " ",
    textOneColor:"TEXT-DARK",
    textTwo:'Earn and Redeem Your Points at ',
    textThree:"Massy Partners",
    descriptionOne: "Our rewards program gives you so much more! You can earn and and redeem your points at select partners.",
    descriptionTwo: "*Special conditions apply. Click here to view our partner earning/redemption structure.",
    descriptionType:"TYPE-ONE",
    columnSize:3,
    CardType:"background-white-second",
    applyBackground:false,
    commonCardContent:[
        {image:MassyStores},
        {image:CaribbeanAirlines},
        {image:MassyMotors},
        {image:massyAutomotiveComponentsLimited},
        {image:MassyFinance},
        {image:Trinpad},
        {image:FerreiraOptical},
    ]
}
const MassyFAQData = {
    textOne: 'Frequently Asked Questions',
    faqData:TrinidadFAQ.slice(0, 4)
}

export { MassyLoyaltyCardData,MassyPointData ,MassyRedeemPointData,MassyTierData,MassyPartnerData,MassyFAQData}
