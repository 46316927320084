const AuthMethod = {
    LOGIN: "LOGIN",
    SIGN_UP: "SIGN_UP"
};

const Views = {
    SIGN_UP : "SIGN_UP",
    CARD_SERACH_VIEW:"CARD_SEARCH_VIEW",
    NEW_USER_SIGN_UP : "NEW_USER_SIGN_UP",
    EXISTING_USER_SIGN_UP : "EXISTING_USER_SIGN_UP"
}

const VerifyMethods = {
    EMAIL: "EMAIL",
    SMS: "MOBILE"
};

const PreferredContactMethods = {
    Email:"Email",
    Mobile_App:"Mobile App",
    Mobile_Text:"Mobile Text",
    Mobile_Call:"Mobile Call",
    Landline:"Landline",
};

export { AuthMethod, Views, VerifyMethods ,PreferredContactMethods};