const isEqualObjects = (a, b) => {
  let s = (o) =>
    Object.entries(o)
      .sort()
      .map((i) => {
        if (i[1] instanceof Object) i[1] = s(i[1]);
        return i;
      });
  return JSON.stringify(s(a)) === JSON.stringify(s(b));
};

const isEmptyObject = (object) => {
  return Object===''||Object.keys(object).length === 0;
};

export { isEqualObjects, isEmptyObject };
